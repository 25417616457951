<!--
 * @Author: your name
 * @Date: 2021-04-29 13:41:24
 * @LastEditTime: 2021-04-29 16:36:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/components/Form.vue
-->
<template>
	<div class="form">
		<div class="form_back" :style="'background:url('+baseImgUrl+'form_pop_back.png);background-size:contain;background-repeat:no-repeat;'">
			<div class="form_content">
				<div class="input_contianer">
					<el-input v-model="name" placeholder="请输入您的姓名"></el-input>
					<el-input v-model="phone" placeholder="请输入您的联系方式"></el-input>
				</div>
				<div class="code_contianer">
					<el-input v-model="code" placeholder="请输入验证码"></el-input>
					<button class="code_btn" @click="sendCode()" :disabled="codeBtnDisabled">{{codeText}}</button>
				</div>
				<button class="add_btn" @click="submit()">免费领取加盟名额</button>
			</div>
			<img class="close_pop" @click="closePop()" :src="baseImgUrl + 'close_pop.png'" alt="">
		</div>
	</div>
</template>

<script>
import { config } from '../config';
import * as api from '../utils/api';
export default {
	data () {
		return {
			baseImgUrl:config.baseImgUrl,
			name:'',
			phone:'',
			code:'',
			countdown:60,
			codeText:'获取验证码',
			codeBtnDisabled:false,
			key:'',
		};
	},

	created(){},

   	methods: {
		sendCode(){
			if(this.name === ''){
				this.$message.error('请输入姓名')
				return false
			}
			if(this.phone === ''){
				this.$message.error('请输入手机号')
				return false
			}
			let params = {
				phone:this.phone,
				name:this.name
			}
			api.sendCode(params).then(res=>{
				if(res.status === 1){
					this.timeLimit();
					this.key = res.data.key
				}else{
					this.$message.error(res.msg);
				}
			})
		},
		submit(){
			if(this.name === ''){
				this.$message.error('请输入姓名')
				return false
			}
			if(this.phone === ''){
				this.$message.error('请输入手机号')
				return false
			}
			if(this.code === ''){
				this.$message.error('请输入短信验证码')
				return false
			}
			api.add({
				name:this.name,
				key:this.key,
				code:this.code,
				channel:''
			}).then(res=>{
				if(res.status === 1){
					this.$message.success(res.msg);
					this.phone = '';
					this.code = '';
					this.name = '';
					this.countdown = 0;
				}else{
					this.$message.error(res.msg)
				}
			})
		},
		timeLimit(){
			let times = setInterval(() => {
				if (this.countdown == 0) {
					clearInterval(times);
					this.codeBtnDisabled = false;
					this.codeText = '获取验证码'
					this.countdown = 60;
					return;
				} else {
					this.codeBtnDisabled = true;
					this.codeText = '重新发送('+this.countdown+'s)';
					this.countdown--;
				}
			}, 1000);
		},
		closePop(){
			this.$emit('closePop');
		}
	}
}

</script>
<style lang='scss' scoped>
	.form{
		position: fixed;
		width:100%;
		height:100%;
		background: rgba(0,0,0,.7);
		top:0;
		left:0;
		z-index: 1000;
		.form_back{
			width:660px;
			height:400px;
			position: absolute;
			top:50%;
			left:50%;
			transform: translate(-50%,-50%);
			.form_content{
				position: absolute;
				right:40px;
				top:80px;
				width: 300px;
				.input_contianer{
					.el-input{
						margin-top:20px;
					}
				}
				.code_contianer{
					margin-top:20px;
					display: flex;
					justify-content: space-between;
					.el-input{
						width:150px;
					}
					.code_btn{
						width:110px;
						line-height: 40px;
						background: #fff;
						color: #B91829;
						font-size:15px;
						border-radius: 4px;
						cursor: pointer;
					}
					.code_btn:disabled{
						color:#999999;
					}
				}
				.add_btn{
					width:300px;
					height: 50px;
					border-radius: 25px;
					background: #FDE8C5;
					color:#B91829;
					font-size: 22px;
					font-weight: bold;
					margin-top:40px;
					cursor: pointer;
				}
			}
			.close_pop{
				position: absolute;
				right:-60px;
				top:-60px;
				width:40px;
				height:40px;
				cursor: pointer;
			}
		}
	}

</style>